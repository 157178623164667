@import url('https://fonts.googleapis.com/css?family=Oswald|Roboto+Condensed&display=swap');

.App {
  display: block;
  background-color: black;
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, #1B4E7D 100%); 
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.1), inset 0px 0px -10px rgba(255, 255, 255, 0.1);
  font-family: 'Oswald', sans-serif;
  color: whitesmoke;
}

