@import url('https://fonts.googleapis.com/css?family=Oswald|Roboto+Condensed&display=swap');

body {
  background-color: black;
  margin: 0;
  font-family: 'Oswald', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.1), inset 0px 0px -10px rgba(255, 255, 255, 0.1);
  color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-family: "Roboto Condensed",
}

.columnTitles {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  h1 {
    font-size: 1.2rem;
  }
  font-size: .85rem;
}

.countersDetails {
  h5 {
    font-size: 1rem;
  }
}

.reverseCounterButton {
  height: 50%;
  font-size: .7rem;
  text-align: center;
  padding: .25rem;
}

@media only screen and (min-width:600px) {
  .reverseCounterButton {
    font-size: .75rem;
  }
}

@media only screen and (min-width:768px) {
  .reverseCounterButton {
    font-size: 1rem;
  }

  .columnTitles {
    h1 {
      font-size: 2rem;
    }

    font-size: 1rem;
  }

  .countersDetails {
    h5 {
      font-size: 1.2rem;
    }
  }
}

// GLOBAL
.alert {
  padding: .25rem 1rem;
}

.form-check-label{
  // vertically centers checkboxes regardless of label font size
  display: flex;
  align-items: center;
}

.form-check-input {
  // vertically centers checkboxes regardless of label font size
  margin-top: 0 !important;
}

.modal-dialog {
  max-width: 1000px !important;
}

.toonLock {
  font-size: .85rem;

  @media only screen and (min-width:768px) {
    font-size: .95rem;
  }

  @media only screen and (min-width:992px) {
    font-size: 1.2rem;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  box-shadow: none;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 1px !important;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #2c2c2c;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.cursor-pointer{
  cursor: pointer;
}

// Character Table
.cellFinished {
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
}

.cellUnfinished {
  color: black;
  background-color: rgba(255, 0, 0, 0.644);
}

.nameColumn {
  width: 40%
}

.dataColumn {
  width: 12%
}